const TVsIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6444_8889)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <path
        d="M13.75 14.625H34.25C34.25 14.625 35.25 14.625 35.25 15.625V28.625C35.25 28.625 35.25 29.625 34.25 29.625H13.75C13.75 29.625 12.75 29.625 12.75 28.625V15.625C12.75 15.625 12.75 14.625 13.75 14.625Z"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 29.625L13.5 33.375"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.25 29.625L34.5 33.375"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6444_8889">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TVsIcon;
