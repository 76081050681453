import { palette } from 'uibook';

export const navBar = {
  backgroundColor: palette.blue[500],
  bottomBorder: '0',
  linkColor: palette.white,
  hoverLinkColor: palette.pink[300],
  disabledLinkColor: palette.charcoal[300],
  menuBarsColor: palette.pink[300],
};
