import React from 'react';

const TabletsIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7097_698)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <path
        d="M31.3636 12.9885H16.6364C15.7326 12.9885 15 13.6452 15 14.4552V33.5219C15 34.3319 15.7326 34.9885 16.6364 34.9885H31.3636C32.2674 34.9885 33 34.3319 33 33.5219V14.4552C33 13.6452 32.2674 12.9885 31.3636 12.9885Z"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.38 30.9885C24.2792 30.9885 24.1826 31.0286 24.1113 31.0998C24.04 31.1711 24 31.2677 24 31.3685C24 31.418 24.0099 31.4669 24.0291 31.5125C24.0483 31.558 24.0765 31.5993 24.1119 31.6338C24.1473 31.6682 24.1893 31.6953 24.2353 31.7133C24.2814 31.7313 24.3306 31.7399 24.38 31.7385C24.429 31.7399 24.4777 31.7313 24.5232 31.7132C24.5687 31.6951 24.6101 31.6679 24.6447 31.6332C24.6793 31.5986 24.7065 31.5572 24.7246 31.5117C24.7428 31.4662 24.7514 31.4175 24.75 31.3685C24.7513 31.3191 24.7428 31.2699 24.7248 31.2239C24.7068 31.1778 24.6797 31.1358 24.6452 31.1004C24.6107 31.065 24.5695 31.0368 24.5239 31.0176C24.4784 30.9984 24.4294 30.9885 24.38 30.9885"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7097_698">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TabletsIcon;
