import { palette } from 'uibook';

export const productDetailsPage = {
  mobileImageSection: {
    backgroundColor: palette.blue[500],
    color: palette.white,
    conditionNew: palette.blue[200],
    conditionRefurb: palette.pink[300],
  },
};
