import { GrowthBook } from '@growthbook/growthbook-react';

export const initialiseGrowthBook = () => {
  const GROWTH_BOOK_API_HOST = 'https://cdn.growthbook.io';

  const devMode =
    process.env.NEXT_PUBLIC_GROWTH_BOOK_ENV === 'development' ||
    process.env.NEXT_PUBLIC_GROWTH_BOOK_ENV === 'staging';

  const growthBook = new GrowthBook({
    enableDevMode: devMode,
    apiHost: GROWTH_BOOK_API_HOST,
    clientKey: process.env.NEXT_PUBLIC_GROWTH_BOOK_CLIENT_KEY,
    trackingCallback: (experiment, result) => {
      if (!window?.analytics?.user) return;

      window.analytics.track('Experiment Viewed', {
        experimentId: experiment.key,
        variationId: result.key,
      });
    },
  });

  growthBook.init();

  return growthBook;
};
