import { affordableMobiles } from './affordableMobiles';
import { uSwitch } from './uSwitch';

export const themes = [
  {
    domain: 'affordablemobiles',
    theme: affordableMobiles,
  },
  {
    domain: 'uswitch',
    theme: uSwitch,
  },
];
