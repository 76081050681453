import pluralize from 'pluralize';
import { capitalize } from './strings.utils';

const getCategoryName = (category: string): string => {
  return category === 'tvs' ? 'TVs' : capitalize(category);
};

const pluralizeWithCase = (displayName: string) => {
  const isUpperCaseWord = displayName === displayName?.toUpperCase();

  if (isUpperCaseWord) {
    return pluralize(displayName).replace(/S$/, 's');
  } else {
    return pluralize(displayName);
  }
};

export { getCategoryName, pluralizeWithCase };
