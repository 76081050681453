import { uSwitchPalette } from './colours';

export const aboutUsPage = {
  heroSection: {
    backgroundColor: uSwitchPalette.blue,
    copyColor: uSwitchPalette.brand.navy,
    button: {
      borderRadius: '0',
      color: uSwitchPalette.white,
      background: uSwitchPalette.brand.navy,
    },
  },
  ourCustomers: {
    headerColor: uSwitchPalette.brand.navy,
    copyColor: uSwitchPalette.brand.navy,
  },
  whyLease: {
    headerColor: uSwitchPalette.brand.navy,
    copyColor: uSwitchPalette.brand.navy,
  },
  howItWorks: {
    stepDisplay: {
      backgroundColor: uSwitchPalette.brand.navy,
    },
    headerColor: uSwitchPalette.brand.navy,
  },
};
