import { rayloLight } from '../rayloLight';
import { affordableMobilesPalette, rayloPayColors } from './colours';

export const footer = {
  ...rayloLight.footer,
  backgroundColor: '#1a2156',
  linkColor: affordableMobilesPalette.white,
  headerColor: rayloPayColors.lightBlue,
  rayloPayLogoColor: affordableMobilesPalette.white,
};
