import { palette } from 'uibook';

export const productIndexPage = {
  catDisplayCard: {
    backgroundColour: palette.charcoal[200],
  },
  button: {
    hover: {
      backgroundColour: 'none',
    },
  },
};
