import { uSwitchPalette } from './colours';

export const checkoutBar = {
  deliveryDateSection: {
    iconColor: uSwitchPalette.brand.navy,
  },
  button: {
    backgroundColor: uSwitchPalette.brand.navy,
    borderRadius: '0',
    hover: {
      backgroundColor: '#53535e',
      opacity: '1',
    },
  },
};
