const ConsolesIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7097_709)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <path
        d="M16.5132 17.312C17.913 17.0297 21.0434 16.7144 23.9997 16.7144C26.9559 16.7144 30.0863 17.0297 31.4861 17.312C31.8307 17.3817 32.1186 17.6063 32.2707 17.9234C33.4652 20.4104 34.2352 25.4713 34.2003 27.6429C34.1627 29.9657 32.5687 31.3004 30.5566 31.2857C28.0477 31.2692 27.3253 27.6392 26.7918 26.409H21.2075C20.674 27.6392 19.9517 31.2692 17.4427 31.2857C15.4307 31.2994 13.8366 29.9657 13.799 27.6429C13.7642 25.4713 14.5342 20.4104 15.7286 17.9234C15.8027 17.7675 15.9123 17.631 16.0485 17.5248C16.1847 17.4187 16.3439 17.3458 16.5132 17.312Z"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4219 23.1512V19.49"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5918 21.3206H21.2539"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.602 20.4352C29.5073 20.4305 29.4181 20.3895 29.3528 20.3209C29.2874 20.2522 29.251 20.161 29.251 20.0662C29.251 19.9714 29.2874 19.8803 29.3528 19.8116C29.4181 19.7429 29.5073 19.702 29.602 19.6973"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M29.6016 20.4352C29.6962 20.4305 29.7855 20.3895 29.8508 20.3209C29.9161 20.2522 29.9526 20.161 29.9526 20.0662C29.9526 19.9714 29.9161 19.8803 29.8508 19.8116C29.7855 19.7429 29.6962 19.702 29.6016 19.6973"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M27.3715 22.943C27.2769 22.9383 27.1876 22.8973 27.1223 22.8287C27.0569 22.76 27.0205 22.6688 27.0205 22.574C27.0205 22.4792 27.0569 22.3881 27.1223 22.3194C27.1876 22.2507 27.2769 22.2098 27.3715 22.2051"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M27.3711 22.943C27.4658 22.9383 27.555 22.8973 27.6203 22.8287C27.6857 22.76 27.7221 22.6688 27.7221 22.574C27.7221 22.4792 27.6857 22.3881 27.6203 22.3194C27.555 22.2507 27.4658 22.2098 27.3711 22.2051"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7097_709">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ConsolesIcon;
