import { NavBarWrapper, type NavDataModel, AboutSubMenuLinksEnum } from 'uibook';
import { useMemo } from 'react';
import { Link } from '../../elements/Link/Link';
import { useRouter } from 'next/router';
import { consumerTypePathBase } from '@/types/consumerTypes';
import { getAlternateConsumerUrl } from '@/utils/urls.utils';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useAppContext } from '@/hooks/useAppContext';
import { useCustomerContext } from '@/hooks/useCustomerContext';

const GlobalNavBar = ({ navData }: { navData: NavDataModel }) => {
  const { consumerType } = useConsumerTypeContext();
  const { isRayloPay } = useAppContext();
  const { hasLoggedInCustomer, isMobileApp } = useCustomerContext();
  const router = useRouter();

  const hasAuthenticatedUser = useMemo(() => hasLoggedInCustomer, [hasLoggedInCustomer]);

  /**
   * Don't render the NavBar if the customer is logged in, or if the user is on the recommendations
   * page (in reality, users should only see the recommendations page if they are logged in, but put
   * this extra check here for local dev purposes).
   */
  if (
    hasAuthenticatedUser ||
    ['/recommendations', '/business/recommendations'].includes(router.asPath)
  ) {
    return null;
  }

  const toggleUrl = getAlternateConsumerUrl(consumerType, router.asPath);

  const aboutSubMenuLinksOverrides = (() => {
    /**
     * If the app is running on a merchant store, override the "ABOUT_US" link to point to `/about`
     * instead of `raylo.com/about`.
     */
    return isRayloPay
      ? [
          {
            displayName: AboutSubMenuLinksEnum.ABOUT_US,
            link: '/about',
          },
        ]
      : undefined;
  })();

  if (isMobileApp) {
    return;
  }

  return (
    <NavBarWrapper
      app="products"
      accountHomeUrl={process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}
      productsUrl={consumerTypePathBase[consumerType]}
      navData={navData}
      LinkComponent={Link}
      consumerType={consumerType}
      toggleUrl={toggleUrl}
      aboutSubMenuLinksOverrides={aboutSubMenuLinksOverrides}
    />
  );
};

export default GlobalNavBar;
