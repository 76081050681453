import constate from 'constate';
import { useRayloCookiesContext } from './useRayloCookiesContext';
import { GET_PREAPPROVAL_DATA } from '@/graphql/operations/getPreApprovalData';
import { useQuery } from '@apollo/client';
import { useConsumerTypeContext } from './useConsumerTypeContext';
import { useAuthTokenContext } from './useAuthTokenContext';
import { userIsLoggedIn } from '@/utils/auth/userAuth';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { isNativeAppWebView } from 'uibook/utils/isNativeAppWebView';

const useCustomer = () => {
  const { authToken } = useAuthTokenContext();
  const { consumerMoneyField } = useConsumerTypeContext();
  const router = useRouter();
  const { cookieValues, setDomainCookie } = useRayloCookiesContext();
  const {
    raylo_checkoutContext: checkoutContext,
    raylo_checkoutToken: checkoutToken,
    raylo_subscriptionId: subscriptionId,
  } = cookieValues;

  const hasAuthenticatedUser = useMemo(() => userIsLoggedIn(authToken), [authToken]);

  const { data: customerData, loading: isCustomerDataLoading } = useQuery(GET_PREAPPROVAL_DATA, {
    skip: !hasAuthenticatedUser,
  });

  const preApproval = customerData?.customer?.preApproval;

  const inAddTechContext =
    hasAuthenticatedUser && (checkoutContext === 'ADDITIONAL_TECH' || !checkoutContext);

  const eligibleForAddTech = inAddTechContext && !!preApproval?.successful;

  const eligibleForUpgrade =
    hasAuthenticatedUser && checkoutContext === 'UPGRADE' && !!subscriptionId;

  const addingNewBusiness = checkoutContext === 'ADD_NEW_BUSINESS';

  /**
   * Due to how Next.js builds the server-generated content, and how the component tree is hydrated,
   * we should default the `hasLoggedInCustomer` value to `false` to prevent a hydration issue.
   * Then, client-side, we can update the state to reflect the actual value, which doesn't cause any
   * hydration issues.
   */
  const [hasLoggedInCustomer, setHasLoggedInCustomer] = useState(false);
  const [isMobileApp, setIsMobileApp] = useState(false);

  /** Update the state client-side so that the app has chance to read the cookie or window values */
  useEffect(() => {
    setHasLoggedInCustomer(
      eligibleForAddTech || eligibleForUpgrade || addingNewBusiness || isMobileApp,
    );
  }, [addingNewBusiness, eligibleForAddTech, eligibleForUpgrade, isMobileApp]);

  useEffect(() => {
    setIsMobileApp(isNativeAppWebView());
  }, []);

  const accountSwitcherData = useMemo(() => {
    if (!customerData?.customer) {
      return null;
    }

    const accountsOnClick = (
      accountName: string,
      accountId: string,
      accountIsBusiness: boolean,
    ) => {
      if (eligibleForUpgrade || !eligibleForAddTech) {
        window.location.href = `${process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}/account/overview/${accountName}`;
        return;
      }

      setDomainCookie('checkoutContext', 'ADDITIONAL_TECH');
      setDomainCookie('activeAccountId', accountId);

      const slug = (() => {
        if (router.query?.product) {
          return `/${accountIsBusiness ? 'business' : ''}/products/${router.query.product}?term=${router.query.term}`;
        } else {
          return `/${accountIsBusiness ? 'business' : ''}/products`;
        }
      })();
      router.push(slug);
    };

    const organizations = customerData.customer.organizations.map((org) => ({
      id: org.id,
      name: org.name,
      isBusiness: true,
      onClick: () => accountsOnClick(org.name.replaceAll(' ', '-').toLowerCase(), org.id, true),
    }));

    const name = `${customerData.customer.firstName} ${customerData.customer.lastName}`;

    const personalAccount = {
      name,
      id: customerData.customer.id,
      isBusiness: false,
      onClick: () => accountsOnClick('personal', customerData?.customer?.id ?? '', false),
    };

    const hasPersonalOrders = customerData.customer.orders.some((order) => !order.organization);

    return {
      customerName: `${customerData?.customer?.firstName} ${customerData?.customer?.lastName}`,
      email: customerData.customer.email,
      accounts: [...(hasPersonalOrders ? [personalAccount] : []), ...organizations],
    };
  }, [customerData, setDomainCookie, eligibleForUpgrade, eligibleForAddTech, router]);

  return {
    isCustomerDataReady: !hasAuthenticatedUser || isCustomerDataLoading === false,
    hasLoggedInCustomer,
    isMobileApp,
    eligibleForAddTech,
    eligibleForUpgrade,
    subscriptionId,
    checkoutContext,
    checkoutToken,
    formattedPreApprovedAmount:
      preApproval?.recurringTaxableAmount?.[consumerMoneyField]?.formattedValue,
    preApprovedAmount: preApproval?.recurringTaxableAmount?.[consumerMoneyField]?.value,
    accountSwitcherData,
  };
};

const [CustomerProvider, useCustomerContext] = constate(useCustomer);
export { CustomerProvider, useCustomerContext };
