import { useAuthTokenContext } from '@/hooks/useAuthTokenContext';
import { DemoModeBanner } from 'uibook';

export const DemoModeBannerWrapper = () => {
  const { rayloDemoMode } = useAuthTokenContext();

  if (!rayloDemoMode) {
    return null;
  }

  return <DemoModeBanner />;
};
