import { px2Rem } from './../../../utils/px2Rem';
import { affordableMobilesPalette } from './colours';

export const aboutUsPage = {
  heroSection: {
    backgroundColor: affordableMobilesPalette.brand.purple,
    copyColor: affordableMobilesPalette.white,
    button: {
      borderRadius: px2Rem(50),
      color: affordableMobilesPalette.white,
      background: affordableMobilesPalette.brand.orange,
    },
  },
  ourCustomers: {
    headerColor: affordableMobilesPalette.brand.purple,
    copyColor: affordableMobilesPalette.charcoal[500],
  },
  whyLease: {
    headerColor: affordableMobilesPalette.brand.purple,
    copyColor: affordableMobilesPalette.charcoal[500],
  },
  howItWorks: {
    stepDisplay: {
      backgroundColor: affordableMobilesPalette.brand.purple,
    },
    headerColor: affordableMobilesPalette.brand.purple,
  },
};
