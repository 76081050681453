import React from 'react';

const DesktopsIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7524_3865)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <g clipPath="url(#clip1_7524_3865)">
        <path
          d="M15.75 12.4976H32.25C32.25 12.4976 33.75 12.4976 33.75 13.9976V24.4976C33.75 24.4976 33.75 25.9976 32.25 25.9976H15.75C15.75 25.9976 14.25 25.9976 14.25 24.4976V13.9976C14.25 13.9976 14.25 12.4976 15.75 12.4976Z"
          stroke="#ED7C7C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.7667 34.0656C32.7945 34.1762 32.7968 34.2917 32.7732 34.4033C32.7496 34.515 32.7009 34.6197 32.6306 34.7096C32.5604 34.7995 32.4706 34.8722 32.368 34.9221C32.2654 34.972 32.1527 34.9978 32.0387 34.9976H15.9607C15.8466 34.9978 15.7339 34.972 15.6313 34.9221C15.5288 34.8722 15.4389 34.7995 15.3687 34.7096C15.2985 34.6197 15.2497 34.515 15.2261 34.4033C15.2026 34.2917 15.2048 34.1762 15.2327 34.0656L16.3577 29.5656C16.3981 29.4032 16.4918 29.259 16.6237 29.156C16.7557 29.0531 16.9183 28.9973 17.0857 28.9976H30.9137C31.0809 28.9976 31.2434 29.0536 31.3753 29.1565C31.5071 29.2594 31.6009 29.4033 31.6417 29.5656L32.7667 34.0656Z"
          stroke="#ED7C7C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 22.9976H33.75"
          stroke="#ED7C7C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.75 31.9976H20.25"
          stroke="#ED7C7C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.75 31.9976H29.25"
          stroke="#ED7C7C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.25 31.9976H24.75"
          stroke="#ED7C7C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_7524_3865">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_7524_3865">
        <rect width="24" height="24" fill="white" transform="translate(12 11.7476)" />
      </clipPath>
    </defs>
  </svg>
);

export default DesktopsIcon;
