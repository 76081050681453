import { rayloLight } from '../rayloLight';
import { uSwitchPalette } from './colours';

export const button = {
  ...rayloLight.button,
  background: {
    ...rayloLight.button.background,
    primary01: uSwitchPalette.brand.navy,
    primary02: uSwitchPalette.brand.navy,
    primary03: uSwitchPalette.brand.navy,
  },
  borderRadius: '0',
  hover: {
    primary02: '#53535e',
    primary03: '#53535e',
  },
};
