import { affordableMobilesPalette } from './colours';

export const checkoutBar = {
  button: {
    backgroundColor: affordableMobilesPalette.brand.orange,
    borderRadius: '50px',
    hover: {
      backgroundColor: '#ff9b55',
      opacity: '1',
    },
  },
};
