import { px2Rem } from './../../utils/px2Rem';
import styled from 'styled-components';
import { IStyledIcon } from './Icon.types';

const StyledIcon = styled.img<IStyledIcon>`
  display: flex;
  ${({ $margin }) => $margin && `margin: ${$margin}`};
  ${({ $styles }) => $styles}
  ${({ $width }) => ($width ? `width: ${px2Rem($width)}` : 'width: fit-content')};
  ${({ $height }) => ($height ? `height: ${px2Rem($height)}` : 'height: fit-content')};
  ${({ $opacity }) => `opacity: ${$opacity ?? 1}`};
`;

export { StyledIcon };
