export const palette = {
  black: '#000',
  white: '#FFF',
  blue: {
    100: '#F0F1FF',
    200: '#C6CBFF',
    300: '#97A0FF',
    400: '#717DFF',
    500: '#4252FF',
  },
  pink: {
    100: '#FFF8F7',
    200: '#FFE6E1',
    300: '#FFD1C8',
    400: '#FFBFB6',
    500: '#ED7C7C',
  },
  charcoal: {
    100: '#F4F4F4',
    200: '#E0E0E0',
    300: '#ABABAB',
    400: '#6C6C6C',
    500: '#2D2D2D',
  },
  error: {
    100: '#FEF2F2',
    200: '#FCCCCD',
    300: '#F9A6A7',
    400: '#F4595C',
    500: '#EE0004',
  },
  success: {
    100: '#F4F9F4',
    200: '#D3E6D2',
    300: '#B1D4B0',
    400: '#6FAE6B',
    500: '#21831C',
  },
  warning: {
    100: '#FFFAF2',
    200: '#FFEBCC',
    300: '#FFDBA6',
    400: '#FFBD5A',
    500: '#FF9901',
  },
} as const;

/** Utility type to prevent deep recursion */
type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}.${P}`
    : never
  : never;

/** Recursive type with a base case */
type DotNotationKeys<T, Depth extends number = 5> = [Depth] extends [never]
  ? never
  : {
      [K in keyof T]: T[K] extends object ? Join<K, DotNotationKeys<T[K], Prev[Depth]>> : K;
    }[keyof T];

/** Decrementing depth to avoid infinite recursion */
type Prev = [never, 0, 1, 2, 3, 4, 5, 6];

/**
 * Define the PaletteColorKeys
 *
 * Output: "black" | "white" | "blue.100" | "blue.200" | "blue.300" etc...
 */
export type PaletteColorKeys = DotNotationKeys<typeof palette>;

/**
 * Retrieves a color from the palette using dot notation.
 *
 * @example
 *   'white' -> '#FFF'
 *
 * @example
 *   'blue.500' -> '#4252FF'
 *
 * @example
 *   'charcoal.300' -> '#ABABAB'
 *
 * @param colorKey - The dot notation key for the desired color, such as `blue.500`.
 * @returns The color value as a string.
 */
export const getPaletteColorByDotNotation = (colorKey: PaletteColorKeys) => {
  const keys = colorKey.split('.');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return keys.reduce((acc, key) => acc[key], palette as any) as string;
};
