import { cva, VariantProps } from 'class-variance-authority';
import { TYPOGRAPHY_VARIANTS_LIST } from 'tailwindcss-config/constants/typography';

/**
 * This is defined here so that all variants are accounted for. If a new variant is added to
 * `TYPOGRAPHY_VARIANTS_LIST` and this is not updated, then it will throw a TS error.
 */
const typographyVariants: Record<(typeof TYPOGRAPHY_VARIANTS_LIST)[number], string | string[]> = {
  h1: 'type-h1',
  h2: 'type-h2',
  h3: 'type-h3',
  h4: 'type-h4',
  body1: 'type-body1',
  body2: 'type-body2',
  fineprint: 'type-fineprint',
};

/** Generate type-safe className props using `class-variance-authority` */
const typographyClassNames = cva(undefined, {
  variants: {
    variant: typographyVariants,
    bold: {
      true: 'font-bold',
    },
    medium: {
      true: 'font-medium',
    },
    italic: {
      true: 'italic',
    },
  },
  defaultVariants: {
    variant: 'body1',
  },
});

/** Typography variants which can be used as elements */
type HeaderElement = Extract<(typeof TYPOGRAPHY_VARIANTS_LIST)[number], 'h1' | 'h2' | 'h3' | 'h4'>;
const headerElements: HeaderElement[] = ['h1', 'h2', 'h3', 'h4'];

const defaultElement = 'p' as const;

type TypographyCvaProps = VariantProps<typeof typographyClassNames>;

type TypographyProps<Element extends React.ElementType = typeof defaultElement> = {
  /**
   * The element use for the `<Typography />` element.
   *
   * By default, if the `variant` is a heading element (h1, h2, h3, h4), it will use that element,
   * but it can be overridden using this `element` prop, if you want to use the `h1` style but as a
   * `h2` or `p` element, etc.
   *
   * @example
   *   'h1';
   *
   * @default 'p'
   */
  element?: Element;
  /**
   * Applies the theme typography styles.
   *
   * @default 'body1'
   */
  variant?: TypographyCvaProps['variant'];
  /**
   * Applies the bold weight to the typography
   *
   * @default false
   */
  bold?: TypographyCvaProps['bold'];
  /**
   * Applies the medium weight to the typography
   *
   * @default false
   */
  medium?: TypographyCvaProps['medium'];
  /**
   * Applies the italic style to the typography
   *
   * @default false
   */
  italic?: TypographyCvaProps['italic'];
} & React.ComponentPropsWithoutRef<Element> & {
    /**
     * @deprecated Pass `text-*` as a `className` instead
     * @url https://tailwindcss.com/docs/text-color
     */
    color?: never;
  };

export const Typography = <Element extends React.ElementType = typeof defaultElement>({
  variant,
  bold,
  medium,
  italic,
  element,
  className,
  children,
  ...otherProps
}: TypographyProps<Element>) => {
  const ElementType = (() => {
    /** If `element` is provided, use it as the element type */
    if (element) {
      return element;
    }
    /** If `variant` is provided and is a heading element, use it as the element type */
    if (variant && headerElements.includes(variant as HeaderElement)) {
      return variant as HeaderElement;
    }

    /** Otherwise, default to 'p' */
    return defaultElement;
  })();

  return (
    <ElementType
      className={typographyClassNames({ variant, bold, medium, italic, className })}
      {...otherProps}
    >
      {children}
    </ElementType>
  );
};
