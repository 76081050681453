import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavDataModel } from '../../../types/navData.types';
import { consumerTypePathBase, ConsumerTypesEnum } from '../../../types/consumerTypes.types';
import { initialAboutSubMenuLinks } from '../utils/utils';

/**
 * Types which are used as props for the `<NavBarContextProvider />` but are not passed to the
 * `<NavBarContext.Provider />`, so cannot be used in the `useNavBarContext()` hook.
 */
type NavBarContextPropsNotPassedToHook = {
  /** If the NavBar is being rendered from the `navbar` app, or the `products` app */
  app: 'navbar' | 'products';
  /** Optional array to override the `aboutSubMenuLinks` */
  aboutSubMenuLinksOverrides?: typeof initialAboutSubMenuLinks;
};

type NavBarContextProps = NavBarContextPropsNotPassedToHook & {
  navData: NavDataModel;
  accountHomeUrl: string;
  productsUrl: string;
  /** Optional React Component to override `a` elements with another element, such as `next/link` */
  LinkComponent?: React.ElementType;
  consumerType: ConsumerTypesEnum;
  toggleUrl: string;
};

export type NavBarContextType = Omit<
  NavBarContextProps,
  keyof NavBarContextPropsNotPassedToHook
> & {
  sideNavOpen: boolean;
  setSideNavOpen: Dispatch<SetStateAction<boolean>>;
  subMenuOpen: string;
  setSubMenuOpen: Dispatch<SetStateAction<string>>;
  handleCloseSideNav: () => void;
  /** Shorthand for when the consumer is in personal mode */
  consumerIsPersonal: boolean;
  /** Shorthand for when the consumer is in business mode */
  consumerIsBusiness: boolean;
  /**
   * If `aboutSubMenuLinksOverrides` is passed to the `NavBarContextProvider`, then this returns the
   * updated `aboutSubMenuLinks` with the overrides. If not, it returns the default
   * `initialAboutSubMenuLinks`.
   */
  aboutSubMenuLinks: typeof initialAboutSubMenuLinks;
  /** Add a query string param to URLs if the `consumerType` is `BUSINESS` */
  suffixUrlsWithConsumerType: (url: string) => string;
};

export const initialNavBarContextState: NavBarContextType = {
  navData: {} as NavDataModel,
  accountHomeUrl: '',
  productsUrl: '',
  LinkComponent: undefined,
  consumerType: ConsumerTypesEnum.PERSONAL,
  consumerIsPersonal: true,
  consumerIsBusiness: false,
  toggleUrl: '',
  sideNavOpen: false,
  setSideNavOpen: () => {},
  subMenuOpen: 'categories',
  setSubMenuOpen: () => {},
  handleCloseSideNav: () => {},
  aboutSubMenuLinks: initialAboutSubMenuLinks,
  suffixUrlsWithConsumerType: () => '',
};

export const NavBarContext = createContext<NavBarContextType>(initialNavBarContextState);

export const NavBarContextProvider = ({
  app,
  navData,
  accountHomeUrl,
  productsUrl: initialProductsUrl,
  LinkComponent,
  consumerType: initialConsumerType,
  toggleUrl,
  aboutSubMenuLinksOverrides,
  children,
}: PropsWithChildren<NavBarContextProps>) => {
  const [sideNavOpen, setSideNavOpen] = useState(initialNavBarContextState.sideNavOpen);
  const [subMenuOpen, setSubMenuOpen] = useState(initialNavBarContextState.subMenuOpen);

  /** Apply any local overrides to the `consumerType` and `productsUrl` if the `app` is `navbar`. */
  const { consumerType, productsUrl } = useMemo(() => {
    /**
     * Handle URLs which are on WebFlow, such as the "about" page, to update URLs in the NavBar so
     * that point to the business pages.
     *
     * - `/about` -> links point to personal consumer product pages
     * - `/about?consumerType=business` -> links point to business consumer product pages
     * - `/business` -> links point to business consumer product pages
     * - `/another-url` -> links point to personal consumer product pages
     */
    if (app === 'navbar' && typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      if (
        window.location.pathname.startsWith(consumerTypePathBase[ConsumerTypesEnum.BUSINESS]) ||
        searchParams.get('consumerType') === ConsumerTypesEnum.BUSINESS
      ) {
        return {
          consumerType: ConsumerTypesEnum.BUSINESS,
          productsUrl: `${initialProductsUrl}${consumerTypePathBase[ConsumerTypesEnum.BUSINESS]}`,
        };
      }
    }
    /** Otherwise, just return whatever has been passed as a prop */
    return {
      consumerType: initialConsumerType,
      productsUrl: initialProductsUrl,
    };
  }, [app, initialConsumerType, initialProductsUrl]);

  useEffect(() => {
    document.documentElement.style.overflow = sideNavOpen ? 'hidden' : 'auto';
  }, [sideNavOpen]);

  const handleCloseSideNav = useCallback(() => {
    setSideNavOpen(false);
    setTimeout(() => {
      setSubMenuOpen('categories');
    }, 300);
  }, []);

  /**
   * Generates the about sub-menu links, applying any overrides if provided. If no overrides are
   * provided, then just return the default `initialAboutSubMenuLinks`.
   */
  const aboutSubMenuLinks = useMemo(() => {
    if (!aboutSubMenuLinksOverrides) {
      return initialAboutSubMenuLinks;
    }
    return initialAboutSubMenuLinks.map((link) => {
      const override = aboutSubMenuLinksOverrides.find(
        (overrideLink) => overrideLink.displayName === link.displayName,
      );
      return override ? { ...link, link: override.link } : link;
    });
  }, [aboutSubMenuLinksOverrides]);

  /** Suffixes URLs with the `consumerType` query parameter if the `consumerType` is `BUSINESS`. */
  const suffixUrlsWithConsumerType = useCallback(
    (url: string) => {
      /** Only apply when the `consumerType` is `BUSINESS` */
      if (consumerType === ConsumerTypesEnum.BUSINESS) {
        /**
         * Some URLs are not a full absolute URL, they're just the pathname onwards. If they start
         * with `/`, assume it's a relative URL
         */
        const isRelativeUrl = url.startsWith('/');
        /** Use a default origin which we can add here, then remove later (if it's a relative URL) */
        const defaultOrigin = 'https://www.raylo.com';
        /**
         * Create a URL, if the `url` being passed in is relative, then use `defaultOrigin` as the
         * `base`, so that `new URL()` does not throw an error.
         */
        const newUrl = new URL(url, isRelativeUrl ? defaultOrigin : undefined);

        /** Set `consumerType` in the search params */
        newUrl.searchParams.set('consumerType', ConsumerTypesEnum.BUSINESS);

        /** If it was a relative URL, remove the domain so it remains a relative URL */
        if (isRelativeUrl) {
          return newUrl.toString().replace(defaultOrigin, '');
        }
        /** Otherwise, return the absolute URL */
        return newUrl.toString();
      }

      /** If it's not a a Business consumerType, just return the `url` which was passed in */
      return url;
    },
    [consumerType],
  );

  const value = useMemo(
    () => ({
      navData,
      accountHomeUrl,
      productsUrl,
      LinkComponent,
      consumerType: consumerType,
      consumerIsBusiness: consumerType === ConsumerTypesEnum.BUSINESS,
      consumerIsPersonal: consumerType === ConsumerTypesEnum.PERSONAL,
      toggleUrl,

      sideNavOpen,
      setSideNavOpen,
      subMenuOpen,
      setSubMenuOpen,
      handleCloseSideNav,
      aboutSubMenuLinks,
      suffixUrlsWithConsumerType,
    }),
    [
      navData,
      accountHomeUrl,
      productsUrl,
      LinkComponent,
      consumerType,
      toggleUrl,
      sideNavOpen,
      subMenuOpen,
      handleCloseSideNav,
      aboutSubMenuLinks,
      suffixUrlsWithConsumerType,
    ],
  );

  return <NavBarContext.Provider value={value}>{children}</NavBarContext.Provider>;
};

export const useNavBarContext = () => {
  return useContext(NavBarContext);
};
