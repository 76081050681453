import { affordableMobilesPalette } from './colours';

export const productDetailsPage = {
  mobileImageSection: {
    backgroundColor: '#f4f4f4',
    color: affordableMobilesPalette.charcoal[500],
    conditionNew: affordableMobilesPalette.brand.purple,
    conditionRefurb: affordableMobilesPalette.brand.orange,
  },
};
