export enum AboutSubMenuLinksEnum {
  ABOUT_US = 'About us',
  BLOG = 'Blog',
  SUSTAINABILITY = 'Sustainability',
  CAREERS = 'Careers',
}

export type Account = {
  id: string | null;
  name: string;
  isBusiness: boolean;
  onClick: () => void;
};

export type CategoryItem = {
  name: string;
  makes: {
    name: string | null | undefined;
    models: {
      displayName: string | null | undefined;
      slug: string | undefined;
    }[];
  }[];
};

export type NavDataModel = {
  categories: CategoryItem[];
};

export type Category = {
  __typename?: 'ProductCategory';
  id: string;
  slug: string;
  displayName?: string | null;
};

export type ProductCategoriesQuery = {
  __typename?: 'Query';
  productCategories: Category[];
};

enum VariantCondition {
  New = 'NEW',
  Refurbished = 'REFURBISHED',
}

export type ProductsQuery = {
  __typename?: 'Product';
  available: boolean;
  id: string;
  displayName?: string | null;
  variantConditions: Array<VariantCondition>;
  position: number;
  manufacturer: {
    __typename?: 'Manufacturer';
    displayName?: string | null;
  };
  category: {
    __typename?: 'ProductCategory';
    displayName?: string | null;
  };
  lowestCostSummary?: {
    __typename?: 'VariantCostSummary';
    id: string;
    termLength: number;
    recurring: {
      __typename?: 'PricePlanRecurringCostSummary';
      totalAmount: {
        __typename?: 'TaxableAmount';
        valueAfterTax: {
          __typename?: 'Money';
          value: number;
        };
      };
      insuranceAmount?: {
        __typename?: 'TaxableAmount';
        valueAfterTax: {
          __typename?: 'Money';
          value: number;
        };
      } | null;
    };
  } | null;
  variants: Array<{
    __typename?: 'Variant';
    id: string;
    displayName?: string | null;
    available: boolean;
    condition: VariantCondition;
    position: number;
    slug: string;
    images?: Array<{
      __typename?: 'Image';
      url: string;
    }> | null;
    optionValues?: Array<{
      __typename?: 'OptionValue';
      raw?: string | null;
      displayName: string;
      optionType: {
        __typename?: 'OptionType';
        slug: string;
      };
    }> | null;
  }>;
} | null;

export type ProductsWithDefaultVariant = {
  defaultVariant: {
    id: string;
    displayName: string;
    slug: string;
    make?: string;
    position: number;
    available: boolean;
  };
  displayName: string | null | undefined;
  catDisplayName: string;
  position: number;
  make: string;
  variantSlug: string;
};
