import { DropdownSubMenuProps } from '../Navbar.types';
import { useNavBarContext } from '../NavBarContext/NavBarContext';
import { NavLink } from '../utils/utilComponents';
import { StyledDropdownSubMenu } from './DropdownSubMenu.styles';

export const DropdownSubMenu = ({
  title,
  items,
  toggleMenu,
  menuOpen,
  dataTestId,
  LinkComponent,
}: DropdownSubMenuProps) => {
  const { suffixUrlsWithConsumerType } = useNavBarContext();
  return (
    <StyledDropdownSubMenu
      data-testid={dataTestId}
      $active={menuOpen === title}
      $dropdownHeight={items.length * 49}
      className="dropdown"
    >
      <button data-testid={`${dataTestId}-title`} onClick={() => toggleMenu(title)}>
        {title}
      </button>
      <ul data-testid={`${dataTestId}-submenu`}>
        {items.map(({ displayName, link }) => (
          <li key={displayName}>
            <NavLink
              href={suffixUrlsWithConsumerType(link)}
              title={displayName}
              LinkComponent={LinkComponent}
            />
          </li>
        ))}
      </ul>
    </StyledDropdownSubMenu>
  );
};
