export class AuthTokenInfo {
  readonly value?: string;
  readonly expiresAt?: number;

  constructor(userToken?: string, expiresAt?: string | number) {
    this.value = userToken;
    if (expiresAt !== undefined) {
      this.expiresAt =
        typeof expiresAt === 'number' ? expiresAt : this.tryParseExpiresAt(expiresAt);
    }
  }

  hasValue(): boolean {
    return this.value !== undefined && this.value !== '';
  }

  isExpired(): boolean {
    const expired = this.hasValue() && this.expiresAt && this.expiresAt <= Date.now();
    return !!expired;
  }

  private tryParseExpiresAt(expiresAt: string): number | undefined {
    const parsed = Date.parse(expiresAt);
    if (!isNaN(parsed)) {
      return parsed;
    }

    return undefined;
  }
}
