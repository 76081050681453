import React from 'react';
import { Link } from 'react-router-dom';
import { StyledTextLink } from './styles';
import { ITextLink } from './TextLink.types';

export const TextLink = ({
  dataTestId,
  children,
  fontSize,
  fontColor,
  onClick,
  link,
  href,
  target,
  regular,
  bold,
  noUnderline,
  lineHeight,
  style,
}: ITextLink) => (
  <StyledTextLink
    as={link ? Link : href ? 'a' : 'div'}
    role="link"
    data-testid={dataTestId}
    fontSize={fontSize}
    fontColor={fontColor}
    onClick={
      link || href ? undefined : (e: React.MouseEvent<HTMLElement, MouseEvent>) => onClick?.(e)
    }
    to={link}
    href={href}
    target={target || '_blank'}
    rel="noopener noreferrer"
    aria-hidden="false"
    regular={regular}
    bold={bold}
    noUnderline={noUnderline}
    lineHeight={lineHeight}
    style={style}
  >
    {children}
  </StyledTextLink>
);
