import { gql } from '../generated';

export const GET_PREAPPROVAL_DATA = gql(`
  query AccountGetPreApprovalDataWithTaxQuery {
  customer {
    id
    firstName
    lastName
    email
    organizations {
      id
      name
    }
    orders {
      organization {
        id
      }
    }
    preApproval {
      recurringTaxableAmount {
        valueAfterTax {
          formattedValue
          value
        }
        valueBeforeTax {
          formattedValue
          value
        }
      }
      successful
    }
  }
}`);
