import { setContext } from '@apollo/client/link/context';
import { AuthTokenInfo } from '../auth/authTokenInfo';
import { clientAuthTokenProvider } from '../auth/clientAuth';
import { getUserAuthTokenProvider } from '../auth/userAuth';

export type UserAuthLinkParams = {
  authToken: AuthTokenInfo;
};

export const getUserAuthLink = ({ authToken }: UserAuthLinkParams) => {
  const userAuthTokenProvider = getUserAuthTokenProvider(authToken);
  return setContext(async (_, { headers }) => {
    await userAuthTokenProvider.renewIfNeeded();
    return {
      headers: {
        ...headers,
        ['X-User-Token']: userAuthTokenProvider.token.value,
      },
    };
  });
};

export const clientAuthLink = setContext(async (_, { headers }) => {
  await clientAuthTokenProvider.renewIfNeeded();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${clientAuthTokenProvider.token.value}`,
    },
  };
});
