import React from 'react';
import { ModalStyles, StyledBaseModal } from './BaseModal.styles';
import { IBaseModal } from './BaseModal.types';
import ReactModal from 'react-modal';
ReactModal.setAppElement('*');

export const BaseModal = ({
  dataTestId,
  modalOpen,
  closeModal,
  children,
  desktopWidth,
  mobileWidth,
  offsetTop,
  maxWidth,
}: IBaseModal) => {
  return (
    <>
      <ModalStyles
        isOpen={modalOpen}
        desktopWidth={desktopWidth}
        mobileWidth={mobileWidth}
        offSetTop={offsetTop}
        maxWidth={maxWidth}
      />
      <StyledBaseModal data-testid={dataTestId}>
        <ReactModal
          data-testid="base-modal"
          isOpen={modalOpen}
          onRequestClose={closeModal}
          contentLabel="My dialog"
          className="modal"
          overlayClassName="overlay"
          closeTimeoutMS={500}
        >
          {children}
        </ReactModal>
      </StyledBaseModal>
    </>
  );
};
