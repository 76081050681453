import { palette } from 'uibook';

export const checkoutBar = {
  button: {
    backgroundColor: palette.blue[500],
    borderRadius: '0',
    hover: {
      backgroundColor: 'rgba(66, 82, 255, 0.7)',
      opacity: '1',
    },
  },
};
