const NEW_BREAKPOINTS = {
  largeDesktop: 1800 as const,
  mobileNav: 1300 as const,
  desktop: 1200 as const,
  smallDesktop: 900 as const,
  tablet: 600 as const,
  mobile: 320 as const,
};

export default NEW_BREAKPOINTS;
