import { clientAuthLink, getUserAuthLink, UserAuthLinkParams } from './apolloAuthLinks';
import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { httpLink } from './apolloHttpLink';
import { cacheConfig } from './apolloCacheConfig';

type MakeApolloClientParams = UserAuthLinkParams;
export const getUserClient = (authTokenInfo: MakeApolloClientParams) =>
  new ApolloClient({
    link: from([clientAuthLink, getUserAuthLink(authTokenInfo), httpLink]),
    cache: new InMemoryCache(cacheConfig),
  });
