import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { px2Rem } from '@/utils/px2Rem';
import styled from 'styled-components';

const StyledLayout = styled.div<{
  $isProductDescriptionPage?: boolean;
  $isShowingPreApprovedBanner?: boolean;
}>`
  ${({ $isProductDescriptionPage, $isShowingPreApprovedBanner }) =>
    $isProductDescriptionPage &&
    `
    padding-bottom: ${$isShowingPreApprovedBanner ? px2Rem(122) : px2Rem(80)};
    
    @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
      padding-bottom: ${px2Rem(112)};
    }
  `}
`;

export { StyledLayout };
