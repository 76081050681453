import { rayloLight } from '../rayloLight';
import { uSwitchPalette } from './colours';

export const footer = {
  ...rayloLight.footer,
  backgroundColor: uSwitchPalette.charcoal[500],
  linkColor: uSwitchPalette.white,
  headerColor: uSwitchPalette.blue[300],
  rayloPayLogoColor: uSwitchPalette.white,
};
