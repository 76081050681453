import { initialiseGrowthBook } from '@/integrations/growthBook/initialiseGrowthBook';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

export const CustomGrowthBookProvider = ({ children }: PropsWithChildren) => {
  const [growthBookAttributesUpdated, setGrowthBookAttributesUpdated] = useState(false);
  const growthBook = useMemo(() => initialiseGrowthBook(), []);

  useEffect(() => {
    if (growthBookAttributesUpdated) return;

    const interval = setInterval(function () {
      if (window?.analytics?.user && growthBook) {
        window?.analytics?.identify();
        growthBook.setAttributes({
          domain: window.location.hostname,
          id: window.analytics.user().anonymousId(),
          customerAnalyticsId: window.analytics.user().id(),
        });
        setGrowthBookAttributesUpdated(true);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [growthBookAttributesUpdated, growthBook]);

  return <GrowthBookProvider growthbook={growthBook}>{children}</GrowthBookProvider>;
};
