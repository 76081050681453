import { footer } from './footer';
import { benefitsInfo } from './benefitsInfo';
import { breadCrumbs } from './breadcrumbs';
import { navBar } from './navbar';
import { logo } from './logo';
import { rayloLight } from '../rayloLight';
import { radioButtons } from './radiobuttons';
import { trustBuilders } from './trustBuilders';
import { aboutUsPage } from './aboutUsPage';
import { typography } from './typography';
import { checkoutBar } from './checkoutBar';
import { productIndexPage } from './productIndexPage';
import { button } from './buttons';
import { productDetailsPage } from './productDetailsPage';
import { frequentlyAskedQuestionCard } from './frequentlyAskedQuestions';

const uSwitchSettings = {
  ...rayloLight,
  logo,
  navBar,
  benefitsInfo,
  footer,
  breadCrumbs,
  radioButtons,
  trustBuilders,
  frequentlyAskedQuestionCard,
  aboutUsPage,
  typography,
  checkoutBar,
  button,
  productIndexPage,
  productDetailsPage,
};

const uSwitch = uSwitchSettings;

export { uSwitch };
