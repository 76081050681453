import { affordableMobilesPalette } from './colours';

export const typography = {
  subHeader: {
    color: affordableMobilesPalette.brand.purple,
  },
  textLink: {
    color: affordableMobilesPalette.brand.purple,
  },
};
