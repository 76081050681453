const HeadphonesIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7097_736)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <path
        d="M31.1745 33.942C31.1745 33.942 34.0109 28.701 33.9941 23.9503C33.9845 21.2998 32.9225 18.7617 31.0416 16.8943C29.1607 15.0269 26.615 13.9832 23.9645 13.9927C21.314 14.0023 18.7759 15.0643 16.9085 16.9452C15.0411 18.8261 13.9974 21.3718 14.0069 24.0222C14.0238 28.7747 16.8984 33.9927 16.8984 33.9927"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7468 26.0369L17.3145 26.6412C16.8625 26.832 16.6507 27.353 16.8414 27.805L18.9999 32.9202C19.1906 33.3722 19.7117 33.584 20.1637 33.3933L21.596 32.7889C22.048 32.5982 22.2598 32.0772 22.069 31.6252L19.9106 26.5099C19.7199 26.0579 19.1988 25.8461 18.7468 26.0369Z"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4046 32.7903L27.8368 33.3946C28.2888 33.5854 28.8099 33.3736 29.0006 32.9216L31.1591 27.8063C31.3498 27.3543 31.138 26.8333 30.686 26.6426L29.2537 26.0382C28.8017 25.8475 28.2807 26.0593 28.0899 26.5113L25.9315 31.6265C25.7407 32.0785 25.9526 32.5995 26.4046 32.7903Z"
        stroke="#ED7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7097_736">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HeadphonesIcon;
