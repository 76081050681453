import { AuthTokenInfo } from './authTokenInfo';
import { AuthTokenProvider } from './authTokenProvider';

export const userIsLoggedIn = (authToken: AuthTokenInfo) =>
  authToken.hasValue() && !authToken.isExpired();

export const getUserAuthTokenProvider = (authToken: AuthTokenInfo) =>
  new AuthTokenProvider('user', () => {
    if (userIsLoggedIn(authToken)) {
      return Promise.resolve({
        token: authToken.value,
        expiresAt: authToken.expiresAt ? new Date(authToken.expiresAt).toISOString() : undefined,
      });
    }
    return Promise.resolve({});
  });
