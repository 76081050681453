import { palette } from 'uibook';
import { px2Rem } from '../../../utils/px2Rem';

export const button = {
  background: {
    primary01: palette.pink[300],
    primary02: palette.pink[500],
    primary03: palette.blue[500],
  },
  hover: {
    primary01: palette.pink[200],
    primary03: palette.blue[400],
    primary02: palette.pink[300],
  },
  fontColor: {
    black: '#000',
    white: '#FFF',
    primary01: palette.charcoal[500],
    primary02: palette.charcoal[500],
    primary03: palette.white,
  },
  active: {
    primary01: palette.pink[300],
    primary02: palette.pink[500],
    primary03: palette.blue[500],
  },
  disabled: {
    background: palette.charcoal[100],
    color: palette.white,
    opacity: '0.4',
  },
  ghostType: {
    disabled: {
      background: 'none',
      color: palette.charcoal[300],
      border: `solid ${px2Rem(1)} ${palette.charcoal[300]}`,
      opacity: '0.4',
    },
    color: palette.charcoal[500],
    background: 'none',
    border: `solid ${px2Rem(1)} ${palette.charcoal[500]}`,
    hover: {
      border: `solid ${px2Rem(1)} rgba(255, 255, 255)`,
    },
    active: {
      border: `solid ${px2Rem(1)} ${palette.charcoal[500]}`,
    },
  },
};
