import React from 'react';
import { palette } from '../../../colors';
import Copy from '../../../elements/Copy';
import TextLink from '../../../elements/TextLink';
import { CalloutEntryProps, CalloutLinkProps } from './Callout.types';

const CalloutLink = (props: CalloutLinkProps) => {
  const text = props.text;
  const href = 'href' in props ? props.href : undefined;
  const onClick = 'onClick' in props ? props.onClick : undefined;
  return (
    <TextLink fontColor={palette.blue[500]} href={href} onClick={onClick} inline>
      {text}
    </TextLink>
  );
};

export const CalloutContent = ({ text, link }: CalloutEntryProps) => {
  return (
    <Copy textAlign="center" color={palette.blue[500]}>
      {text}
      {link && (
        <>
          {' '}
          <CalloutLink {...link} />
        </>
      )}
    </Copy>
  );
};
