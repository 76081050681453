import { uSwitchPalette } from './colours';
import { rayloLight } from '../rayloLight';

export const navBar = {
  ...rayloLight.navBar,
  backgroundColor: uSwitchPalette.white,
  bottomBorder: `solid 1px ${uSwitchPalette.charcoal[200]}`,
  rayloPayLogoColor: uSwitchPalette.blue[500],
  linkColor: uSwitchPalette.charcoal[500],
  hoverLinkColor: uSwitchPalette.charcoal[500],
  disabledLinkColor: uSwitchPalette.charcoal[300],
  menuItemsBackgroundColor: uSwitchPalette.white,
  menuBarsColor: uSwitchPalette.brand.navy,
};
