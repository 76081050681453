import { useMemo } from 'react';
import constate from 'constate';
import pluralize from 'pluralize';
import { pluralizeWithCase } from '@/utils/categoryHelpers';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { SHOW_ONLY_PHONES } from '@/constants/features';
import { ProductCategories } from '../types/productTypes';

const useCategories = ({ categories }: { categories: ProductCategories }) => {
  const showOnlyPhones: boolean = useFeatureIsOn(SHOW_ONLY_PHONES);

  const productCategories = useMemo(() => {
    return (
      categories?.productCategories
        ?.map(({ displayName }) => {
          return {
            displayName: displayName ? pluralizeWithCase(displayName) : '',
            imageUrl: `https://raylo.imgix.net/${pluralize(
              displayName ?? '',
            ).toLowerCase()}-category.png`,
          };
        })
        .filter(({ displayName }) => displayName === 'Phones' || !showOnlyPhones) ?? []
    );
  }, [categories, showOnlyPhones]);

  return {
    productCategories,
  };
};

const [CategoriesProvider, useCategoriesContext] = constate(useCategories);
export { CategoriesProvider, useCategoriesContext };
