import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import { palette } from '../../../colors';
import { TAILWIND_BREAKPOINTS } from '../../../constants';
import { cssShadow } from '../../../foundations/Shadow';

type StyledNavBarConsumerToggleProps = {
  $location: 'sideNav' | 'topNav';
};

export const StyledNavBarConsumerToggle = styled.div<StyledNavBarConsumerToggleProps>`
  ${({ $location }) => `
    display: ${$location === 'topNav' ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    padding: ${px2Rem(24)} ${px2Rem(24)} ${px2Rem(20)};

    ${
      $location === 'topNav' &&
      `
      @media (min-width: ${TAILWIND_BREAKPOINTS.lg}px) {
        display: flex;
        padding: 0;
      }
    `
    }
  `}
`;

export const StyledNavBarConsumerToggleAnchor = styled.a<
  StyledNavBarConsumerToggleProps & { $currentItemIndex: number }
>`
  ${({ $location, $currentItemIndex }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${px2Rem(350)};
    position: relative;
    background-color: ${palette.charcoal[100]};
    border-radius: ${px2Rem(4)};

    // Start: Override WebFlow 'a' styles
    &:hover {
      text-decoration: none;
    }
    &, &:hover, &:active {
      outline: 1px solid ${palette.charcoal[200]};
    }
    // End: Override WebFlow 'a' styles

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
      outline: 1px solid ${palette.blue[500]};
      border-radius: ${px2Rem(4)};
      background-color: ${palette.white};
      transition: transform 0.3s;
      transform: translateX(${$currentItemIndex * 100}%);
    }

    ${
      $location === 'topNav' &&
      `
      @media (min-width: ${TAILWIND_BREAKPOINTS.lg}px) {
        background-color: transparent;
        min-width: ${px2Rem(164)};

        &:before {
          background-color: ${palette.white};
          outline-color: ${palette.white};
          transition:
            transform 0.3s,
            background-color 0.3s;
          box-shadow: ${cssShadow(3)};
        }

        &:hover:before {
          background-color: ${palette.blue[100]};
        }
      }
    `
    }
  `}
`;

export const StyledNavBarConsumerToggleItem = styled.span<
  StyledNavBarConsumerToggleProps & { $isCurrent: boolean }
>`
  ${({ $location, $isCurrent }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: ${px2Rem(12)};
    position: relative;
    z-index: 3;
    color: ${$isCurrent ? palette.blue[500] : palette.charcoal[300]};
    transition: color 0.3s;

    ${
      $location === 'topNav' &&
      `
      @media (min-width: ${TAILWIND_BREAKPOINTS.lg}px) {
        padding: ${px2Rem(7.5)} ${px2Rem(12)};
        color: ${$isCurrent ? palette.blue[500] : palette.blue[200]};
      }
    `
    }
  `}
`;
