import { palette } from 'uibook';

/** Use the uibook palette as a base and add any additional brand colours where needed */
export const uSwitchPalette = {
  ...palette,
  brand: {
    blue: '#bee8f3',
    lightBlue: '#e1f9ff',
    navy: '#141424',
  },
} as const;
