import {
  ConsumerTypesEnum,
  consumerTypeMoneyField,
  type ConsumerTypeMoneyField,
  consumerTypePathBase,
} from 'uibook';

export const consumerTypeKeysList = Object.keys(consumerTypePathBase) as ConsumerTypesEnum[];

export {
  ConsumerTypesEnum,
  consumerTypeMoneyField,
  type ConsumerTypeMoneyField,
  consumerTypePathBase,
};
