import React from 'react';
import { palette } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import Container from '../../../elements/Container';
import { CalloutEntryProps } from './Callout.types';
import { CalloutContent } from './CalloutContent';

export const Callout = ({ text, link }: CalloutEntryProps) => {
  return (
    <Container
      sticky
      stickyTop={0}
      padding={px2Rem(12)}
      backgroundColor={palette.blue[100]}
      styles={{ zIndex: '1' }}
    >
      <CalloutContent text={text} link={link} />
    </Container>
  );
};
