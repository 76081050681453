import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useRayloCookiesContext } from './useRayloCookiesContext';
import { AuthTokenInfo } from '@/utils/auth/authTokenInfo';
import { getUserClient } from '@/utils/apollo/apolloUserClient';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export type AuthTokenContextType = {
  client: ApolloClient<NormalizedCacheObject>;
  authToken: AuthTokenInfo;
  customerIsInMobileApp: boolean;
  rayloDemoMode: boolean;
};

export const initialAuthTokenContextState: AuthTokenContextType = {
  client: {} as ApolloClient<NormalizedCacheObject>,
  authToken: new AuthTokenInfo(),
  customerIsInMobileApp: false,
  rayloDemoMode: false,
};

export const AuthTokenContext = createContext<AuthTokenContextType>(initialAuthTokenContextState);

export const AuthTokenContextProvider = ({ children }: PropsWithChildren) => {
  const { cookieValues } = useRayloCookiesContext();
  const [customerIsInMobileApp, setCustomerIsInMobileApp] = useState<boolean>(false);

  const {
    raylo_userToken: rayloUserToken,
    raylo_userTokenExpiresAt: rayloUserTokenExpiresAt,
    raylo_demoMode: rayloDemoMode,
  } = cookieValues;

  const authToken = useMemo(
    () => new AuthTokenInfo(rayloUserToken, rayloUserTokenExpiresAt),
    [rayloUserToken, rayloUserTokenExpiresAt],
  );

  const client = useMemo(() => getUserClient({ authToken }), [authToken]);

  useEffect(() => {
    setTimeout(() => {
      setCustomerIsInMobileApp(!!window.ReactNativeWebView);
    }, 0);
  }, []);

  const value = useMemo(
    () => ({
      client,
      authToken,
      customerIsInMobileApp,
      rayloDemoMode: !!rayloDemoMode,
    }),
    [client, authToken, customerIsInMobileApp, rayloDemoMode],
  );

  return <AuthTokenContext.Provider value={value}>{children}</AuthTokenContext.Provider>;
};

export const useAuthTokenContext = () => {
  return useContext(AuthTokenContext);
};
