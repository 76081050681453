import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { httpLink } from '../apollo/apolloHttpLink';
import { AuthTokenProvider } from './authTokenProvider';

const ClientAuthMutation = gql`
  mutation ProductsRepoAuthenticateClientMutation($clientId: String!) {
    authenticateClient(clientId: $clientId) {
      accessToken {
        value
        expiresAt
      }
    }
  }
`;

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export const clientAuthTokenProvider = new AuthTokenProvider('client', async () => {
  const result = await client.mutate({
    mutation: ClientAuthMutation,
    variables: { clientId: process.env.NEXT_PUBLIC_REACT_APP_CLIENT_ID },
  });
  const tokenData = result?.data?.authenticateClient?.accessToken;
  return {
    token: tokenData?.value,
    expiresAt: tokenData?.expiresAt,
  };
});
