import React from 'react';
import { StyledCopy } from './styles';
import { ICopy } from './Copy.types';
/** @deprecated This component has been replaced with the `<Typography />` component in `uibook`. */
const Copy = ({
  children,
  color,
  fontSize,
  dataTestId,
  bold,
  medium,
  italic,
  lineHeight,
  textAlign,
  verticalAlign,
  styles,
  letterSpacing,
  fontFamily,
  hoverBold,
  className,
  margin,
  truncate,
}: ICopy) => {
  return (
    <StyledCopy
      margin={margin}
      className={className}
      color={color}
      fontSize={fontSize}
      data-testid={dataTestId}
      bold={bold}
      medium={medium}
      italic={italic}
      lineHeight={lineHeight}
      textAlign={textAlign}
      verticalAlign={verticalAlign}
      styles={styles}
      letterSpacing={letterSpacing}
      fontFamily={fontFamily}
      hoverBold={hoverBold}
      truncate={truncate}
      aria-hidden="false"
    >
      {children}
    </StyledCopy>
  );
};

export default Copy;
