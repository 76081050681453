const SpeakersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <g clipPath="url(#a)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <g
        stroke="#ED7C7C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#b)"
      >
        <path d="M31.714 12.748H16.286c-.71 0-1.286.656-1.286 1.466v19.067c0 .81.576 1.467 1.286 1.467h15.428c.71 0 1.286-.657 1.286-1.467V14.214c0-.81-.576-1.466-1.286-1.466" />
        <path d="M24 31.748a5 5 0 1 0 0-10 5 5 0 0 0 0 10" />
        <path d="M24 27.748a1 1 0 1 0 0-2 1 1 0 0 0 0 2M24 18.748a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <rect width="48" height="48" fill="#fff" rx="24" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M12 11.748h24v24H12z" />
      </clipPath>
    </defs>
  </svg>
);

export default SpeakersIcon;
