import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Combines multiple class names into a single string using `clsx` and merges them using `twMerge`.
 *
 * This should be used when applying conditional classNames to a component.
 *
 * Example where it does not need to be used:
 *
 * ```tsx
 * <div className="grid gap-4">{content}</div>
 * ```
 *
 * Example where it should be used:
 *
 * ```tsx
 * <div
 *   className={cn('grid gap-4', {
 *     'grid-cols-1': hasLongLabel,
 *     'grid-cols-3': !hasLongLabel,
 *   })}
 * >
 *   {content}
 * </div>
 * ```
 *
 * @param {...ClassValue[]} inputs - An array of class values to be combined and merged.
 * @returns {string} - A single string of combined and merged class names.
 * @url https://github.com/dcastil/tailwind-merge
 * @url https://github.com/lukeed/clsx
 */
export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};
