import { uSwitchPalette } from './colours';

export const productIndexPage = {
  catDisplayCard: {
    backgroundColour: uSwitchPalette.charcoal[200],
  },
  button: {
    hover: {
      backgroundColour: '#ff9b55',
    },
  },
};
