import React from 'react';
import { StyledPlusMinusIcon } from './PlusMinusIcon.styles';
import { IPlusMinusIcon } from './PlusMinusIcon.types';

export const PlusMinusIcon = ({
  dataTestId,
  isMinus,
  color,
  size,
  paddingLeft,
  strokeWidth,
}: IPlusMinusIcon) => (
  <StyledPlusMinusIcon data-testid={dataTestId} $size={size} $paddingLeft={paddingLeft}>
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1064_5862)">
        <path
          d="M0.75 12H23.25"
          stroke={color}
          strokeWidth={strokeWidth ?? '1.5'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          data-testid={`${dataTestId}-horizontal`}
          d="M12 0.75V23.25"
          stroke={color}
          strokeWidth={strokeWidth ?? '1.5'}
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ transform: `rotate(${isMinus ? '90deg' : '0'})`, transformOrigin: '50%' }}
        />
      </g>
      <defs>
        <clipPath id="clip0_1064_5862">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledPlusMinusIcon>
);
