import { createGlobalStyle } from 'styled-components';
import { ASSETS_FONTS_BASE_URL, CSS_FONT_FAMILY, CSS_FONT_WEIGHT } from './constants';
import { palette } from './colors';

const fontFamilyNames = {
  sans: 'Raylo',
  mono: 'Raylo-MonoSpaced',
};

const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};

// This component can be imported into any React app which uses styled-components to apply the Raylo font faces and font family CSS custom properties
const GlobalUIStyles = createGlobalStyle`
@font-face {
  font-family: "${fontFamilyNames.sans}";
  font-weight: ${fontWeights.regular};
  src: url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Regular.woff2") format('woff2'),
       url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Regular.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "${fontFamilyNames.sans}";
  font-weight: ${fontWeights.medium};
  src: url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Medium.woff2") format('woff2'),
       url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Medium.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "${fontFamilyNames.sans}";
  font-weight: ${fontWeights.bold};
  src: url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Bold.woff2") format('woff2'),
       url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Bold.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "${fontFamilyNames.mono}";
  src: url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Monospaced.woff2") format('woff2'),
       url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Monospaced.woff") format('woff');
  font-display: swap;
}

:root {
  ${CSS_FONT_FAMILY.properties.sans}: ${fontFamilyNames.sans}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  ${CSS_FONT_FAMILY.properties.mono}: ${fontFamilyNames.mono}, monospace;
  ${CSS_FONT_WEIGHT.properties.regular}: ${fontWeights.regular};
  ${CSS_FONT_WEIGHT.properties.medium}: ${fontWeights.medium};
  ${CSS_FONT_WEIGHT.properties.bold}: ${fontWeights.bold};
}

html,
body,
button,
input,
optgroup,
select,
textarea {
  font-family: var(${CSS_FONT_FAMILY.properties.sans});
}

body {
  color: ${palette.charcoal[500]};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;

export default GlobalUIStyles;
