import { uSwitchPalette } from './colours';

export const productDetailsPage = {
  mobileImageSection: {
    backgroundColor: uSwitchPalette.charcoal[100],
    color: uSwitchPalette.charcoal[500],
    conditionNew: uSwitchPalette.blue[500],
    conditionRefurb: uSwitchPalette.pink[500],
  },
  deliveryDateSection: {
    iconColor: uSwitchPalette.brand.navy,
  },
  refurbTrustbuilder: {
    iconColor: uSwitchPalette.brand.navy,
  },
};
