import { useRouter } from 'next/router';
import { useAppContext } from '../../../hooks/useAppContext';
import { StyledLayout } from '@/components/Layouts/App/styles';
import { ILayout } from '@/components/Layouts/types';
import { ThemeProvider } from 'styled-components';

export const AppLayout = ({ dataTestId, children }: ILayout) => {
  const router = useRouter();
  const { theme, isShowingPreApprovedBanner } = useAppContext();
  const isProductDescriptionPage = router?.asPath.includes('products/');

  return (
    <ThemeProvider theme={theme}>
      <StyledLayout
        data-testid={dataTestId}
        $isProductDescriptionPage={isProductDescriptionPage}
        $isShowingPreApprovedBanner={isShowingPreApprovedBanner}
      >
        {children}
      </StyledLayout>
    </ThemeProvider>
  );
};
