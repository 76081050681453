import { AboutSubMenuLinksEnum } from '../../../types/navData.types';
import { Link } from '../Navbar.types';

/**
 * The default `aboutMenuSubLinks`, which can be overridden in the `NavBarContextProvider`, such as
 * updating the `AboutSubMenuLinksEnum.ABOUT_US` item to point to a URL in the products app.
 */
export const initialAboutSubMenuLinks: Link[] = [
  {
    displayName: AboutSubMenuLinksEnum.ABOUT_US,
    link: 'https://www.raylo.com/about',
  },
  {
    displayName: AboutSubMenuLinksEnum.BLOG,
    link: 'https://www.raylo.com/blog',
  },
  {
    displayName: AboutSubMenuLinksEnum.SUSTAINABILITY,
    link: 'https://www.raylo.com/sustainability',
  },
  {
    displayName: AboutSubMenuLinksEnum.CAREERS,
    link: 'https://www.raylo.com/careers',
  },
];

export const helpSubMenuLinks: Link[] = [
  {
    displayName: 'Help centre',
    link: 'https://help.raylo.com/',
  },
  {
    displayName: 'Contact us',
    link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
  },
];

export const categoryForUrl = (category: string) => {
  return `/products?category=${category.toLowerCase()}`;
};
