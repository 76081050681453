import { aboutUsPage } from './aboutUsPage';
import { productDetailsPage } from './productDetailsPage';
import { trustBuilders } from './trustBuilders';
import { checkoutBar } from './checkoutBar';
import { frequentlyAskedQuestionCard } from './frequentlyAskedQuestions';
import { breadCrumbs } from './breadcrumbs';
import { benefitsInfo } from './benefitsInfo';
import { productIndexPage } from './productIndexPage';
import { typography } from './typography';
import { radioButtons } from './radiobuttons';
import { aboutSubMenu } from './aboutsubmenu';
import { navBar } from './navbar';
import { footer } from './footer';
import { quickLinks } from './quicklinks';
import { trustPilot } from './trustpilot';
import { button } from './buttons';

const rayloLight = {
  button,
  trustPilot,
  quickLinks,
  footer,
  navBar,
  aboutSubMenu,
  radioButtons,
  typography,
  productIndexPage,
  benefitsInfo,
  breadCrumbs,
  frequentlyAskedQuestionCard,
  checkoutBar,
  trustBuilders,
  productDetailsPage,
  aboutUsPage,
};

export { rayloLight };
