import React from 'react';
import { IIcon } from './Icon.types';
import { StyledIcon } from './styles';
import { icons } from './Icons';
import { ASSETS_SVGS_BASE_URL } from '../../constants';

function Icon(props: IIcon) {
  const { name, dataTestId, $margin, $styles, $width, $height, $opacity, loading } = props;

  const icon = icons[name];

  return (
    <StyledIcon
      data-testid={dataTestId}
      $margin={$margin}
      $styles={$styles}
      $width={$width}
      $height={$height}
      $opacity={$opacity}
      src={`${ASSETS_SVGS_BASE_URL}/${icon}`}
      alt={`${name} Icon svg`}
      loading={loading ?? 'lazy'}
    />
  );
}

export default Icon;
