import { StyledBurgerNavButton } from '../NavBarWrapper/NavBarWrapper.styles';
import {
  StyledCloseButton,
  StyledMenuItem,
  StyledMenuListItem,
  StyledNavigateBack,
} from '../SideNavSubMenu/SideNavSubMenu.styles';
import { Link, MenuItemProps, NavigateBackProps } from '../Navbar.types';
import PhoneIcon from '../svgs/phones-nav';
import LaptopsIcon from '../svgs/laptops-nav';
import DesktopsIcon from '../svgs/desktops-nav';
import ConsolesIcon from '../svgs/consoles-nav';
import TabletsIcon from '../svgs/tablets-nav';
import HeadphonesIcon from '../svgs/headphones-nav';
import WatchesIcon from '../svgs/watches-nav';
import TVsIcon from '../svgs/tvs-nav';
import { palette } from '../../../colors';
import SpeakersNav from '../svgs/speakers-nav';

export const BurgerNav = ({
  onClick,
  sideNavOpen,
  dataTestId,
}: {
  onClick?: () => void;
  sideNavOpen: boolean;
  dataTestId?: string;
}) => (
  <StyledBurgerNavButton
    className="burger-nav-button"
    onClick={onClick}
    $sideNavOpen={sideNavOpen}
    data-testid={dataTestId}
  >
    <span data-testid={`${dataTestId}-1`} />
    <span data-testid={`${dataTestId}-2`} />
    <span data-testid={`${dataTestId}-3`} />
  </StyledBurgerNavButton>
);

export const MenuItem = ({
  label,
  onClick,
  icon,
  isBold,
  hideChevron,
  border,
  iconSize,
  dataTestId,
  staticIcon,
  productsUrl,
  LinkComponent,
  as,
}: MenuItemProps) => (
  <StyledMenuListItem as={as} className="menu-item">
    <StyledMenuItem
      data-testid={dataTestId}
      title={label ?? 'raylo product category'}
      as={(LinkComponent ?? typeof onClick === 'string') ? 'a' : 'button'}
      onClick={typeof onClick === 'string' ? undefined : onClick}
      href={typeof onClick === 'string' ? `${productsUrl ?? ''}${onClick}` : undefined}
      $icon={icon}
      $isBold={isBold}
      $hideChevron={hideChevron}
      $border={border}
      $iconSize={iconSize}
      $staticIcon={staticIcon}
    >
      {icon && getIcon(icon)}
      {label}
    </StyledMenuItem>
  </StyledMenuListItem>
);

export const NavigateBack = ({ onClick, title, titleLink }: NavigateBackProps) => (
  <StyledNavigateBack data-testid="back">
    <button data-testid="back-button" onClick={onClick} />
    {titleLink ? <NavLink href={titleLink} title={title} /> : <p>{title}</p>}
  </StyledNavigateBack>
);

type MenuLinksProps = {
  links: Link[];
  dataTestId?: string;
  LinkComponent?: React.ElementType;
};

export const MenuLinks = ({ dataTestId, links, LinkComponent }: MenuLinksProps) => (
  <>
    {links.map(({ displayName, link }, i) => (
      <StyledMenuItem
        data-testid={`${dataTestId}-${i}`}
        key={displayName}
        as={LinkComponent ?? 'a'}
        href={link}
        $hideChevron
      >
        {displayName}
      </StyledMenuItem>
    ))}
  </>
);

export const CloseButton = ({
  onClick,
  dataTestId,
  fillColour,
  hoverFillColour,
}: {
  onClick: () => void;
  dataTestId?: string;
  fillColour?: string;
  hoverFillColour?: string;
}) => {
  const resolvedFillColour = fillColour ?? palette.white;
  const resolvedHoverFillColour = hoverFillColour ?? palette.blue[200];
  return (
    <StyledCloseButton
      data-testid={dataTestId}
      onClick={onClick}
      $fillColour={resolvedFillColour}
      $hoverFillColour={resolvedHoverFillColour}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          data-testid={`${dataTestId}-rect`}
          x="12.707"
          y="12.707"
          width="12"
          height="2"
          transform="rotate(135 12.707 12.707)"
          fill={resolvedFillColour}
        />
        <rect
          x="21.1934"
          y="4.22192"
          width="12"
          height="2"
          transform="rotate(135 21.1934 4.22192)"
          fill={resolvedFillColour}
        />
        <rect
          x="11.293"
          y="12.707"
          width="12"
          height="2"
          transform="rotate(-135 11.293 12.707)"
          fill={resolvedFillColour}
        />
        <rect
          x="19.7773"
          y="21.1924"
          width="12"
          height="2"
          transform="rotate(-135 19.7773 21.1924)"
          fill={resolvedFillColour}
        />
      </svg>
    </StyledCloseButton>
  );
};

const getIcon = (icon: string) => {
  switch (icon) {
    case 'phones':
      return <PhoneIcon />;
    case 'laptops':
      return <LaptopsIcon />;
    case 'desktops':
      return <DesktopsIcon />;
    case 'consoles':
      return <ConsolesIcon />;
    case 'tablets':
      return <TabletsIcon />;
    case 'headphones':
      return <HeadphonesIcon />;
    case 'watches':
      return <WatchesIcon />;
    case 'tvs':
      return <TVsIcon />;
    case 'speakers':
      return <SpeakersNav />;
    default:
      return undefined;
  }
};

export const NavLink = ({
  href,
  title,
  dataTestId,
  LinkComponent,
  className,
}: {
  href: string;
  title: string;
  dataTestId?: string;
  LinkComponent?: React.ElementType;
  className?: string;
}) => (
  <>
    {LinkComponent ? (
      <LinkComponent className={className} data-testid={dataTestId} href={href} title={title}>
        {title}
      </LinkComponent>
    ) : (
      <a className={className} data-testid={dataTestId} href={href} title={title}>
        {title}
      </a>
    )}
  </>
);
