import { rayloLight } from '../rayloLight';
import { affordableMobilesPalette } from './colours';

export const button = {
  ...rayloLight.button,
  background: {
    ...rayloLight.button.background,
    primary01: `${affordableMobilesPalette.brand.orange}`,
    primary02: '#ff6900',
    primary03: '#ff6900',
  },
  borderRadius: '50px',
  hover: {
    primary02: '#ff9b55',
    primary03: '#ff9b55',
  },
};
