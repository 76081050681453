import animationData from './animation.json';
import { px2Rem } from '../../../utils/px2Rem';
import LottieAnimation from '../../LottieAnimation';
import { useNavBarContext } from '../NavBarContext/NavBarContext';

type AnimatedLogoProps = {
  dataTestId?: string;
};

const AnimatedLogo = ({ dataTestId }: AnimatedLogoProps) => {
  const { consumerIsBusiness } = useNavBarContext();
  const href = `https://raylo.com${consumerIsBusiness ? '/business' : ''}`;

  return (
    <a href={href} title="Raylo" style={{ width: px2Rem(132) }} data-testid={dataTestId}>
      <LottieAnimation animationData={animationData} height={32} width={132} />
    </a>
  );
};

export default AnimatedLogo;
