import { StyledDesktopItems, StyledNavBar, StyledNavBarWrapper } from './NavBarWrapper.styles';
import AnimatedLogo from '../AnimatedLogo/AnimatedLogo';
import TrustpilotNavImg from '../svgs/trustpilot-navbar';
import { useState, useEffect, useCallback, useRef } from 'react';
import { SideNav } from '../SideNav/SideNav';
import { BurgerNav, NavLink } from '../utils/utilComponents';
import { categoryForUrl, helpSubMenuLinks } from '../utils/utils';
import { DropdownSubMenu } from '../DropdownSubMenu/DropdownSubMenu';
import { Menu } from '../Navbar.types';
import CalloutCarousel from '../../CalloutCarousel/CalloutCarousel';
import { NavBarContextProvider, useNavBarContext } from '../NavBarContext/NavBarContext';
import { NavBarConsumerToggle } from '../NavBarConsumerToggle/NavBarConsumerToggle';
import { TAILWIND_BREAKPOINTS } from '../../../constants';
import { LoginPrompt } from '../LoginPrompt/LoginPrompt';

export const NavBarWrapper = (props: React.ComponentProps<typeof NavBarContextProvider>) => {
  return (
    <NavBarContextProvider {...props}>
      <NavBarWrapperWithContext />
    </NavBarContextProvider>
  );
};

const NavBarWrapperWithContext = () => {
  const {
    navData,
    accountHomeUrl,
    productsUrl,
    LinkComponent,
    sideNavOpen,
    setSideNavOpen,
    subMenuOpen,
    setSubMenuOpen,
    handleCloseSideNav,
    aboutSubMenuLinks,
    consumerIsBusiness,
  } = useNavBarContext();

  const navRef = useRef<HTMLDivElement>(null);
  const calloutRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState<Menu | null>(null);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const [calloutHeight, setCalloutHeight] = useState<number>(0);
  const oldScrollY = useRef<number>(0);
  const SCROLL_THRESHOLD = 60;

  const handleScroll = useCallback(() => {
    if (window.scrollY < SCROLL_THRESHOLD) {
      setScrollDirection('down');
      setCalloutHeight(0);
      return;
    }
    if (window.scrollY > oldScrollY.current) {
      setScrollDirection('up');
    } else {
      setScrollDirection('down');
    }
    if (window.scrollY > SCROLL_THRESHOLD) {
      setCalloutHeight(calloutRef.current?.getBoundingClientRect().height ?? 0);
    }
    oldScrollY.current = window.scrollY;
  }, []);

  useEffect(() => {
    if (!navRef.current) return;

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (!entry) return;

      if (entry.contentRect.width < TAILWIND_BREAKPOINTS.lg) {
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    });

    observer.observe(navRef.current);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [handleScroll]);

  const toggleDropdown = (dropdown: typeof dropdownOpen) => {
    setDropdownOpen(dropdownOpen === dropdown ? null : dropdown);
  };

  const callouts = [
    <p key="1">Raylo is authorised and regulated by the Financial Conduct Authority</p>,
    <p key="2">As seen on TV</p>,
    <p key="3">Join over 100,000 subscribers</p>,
  ];

  return (
    <>
      <StyledNavBarWrapper
        $calloutHeight={calloutHeight}
        $hideNav={scrollDirection === 'up'}
        ref={navRef}
      >
        <CalloutCarousel callouts={callouts} ref={calloutRef} />
        <StyledNavBar>
          <AnimatedLogo />
          <TrustpilotNavImg className="trustpilot-nav-img" />
          <div className="nav-primary-buttons">
            <div className="quick-links">
              <DropdownSubMenu
                title="About"
                items={aboutSubMenuLinks}
                menuOpen={dropdownOpen}
                toggleMenu={toggleDropdown}
                LinkComponent={LinkComponent}
              />
              <DropdownSubMenu
                title="Help"
                items={helpSubMenuLinks}
                menuOpen={dropdownOpen}
                toggleMenu={toggleDropdown}
              />
              <a
                className="text-link"
                href={`https://www.raylo.com/${consumerIsBusiness ? 'business' : ''}#how-it-works`}
                title="How it works"
              >
                How it works
              </a>

              <NavBarConsumerToggle location="topNav" />

              <LoginPrompt dataTestId="login-prompt-nav" />
              <BurgerNav
                dataTestId="burger-mobile"
                onClick={() => setSideNavOpen(true)}
                sideNavOpen={sideNavOpen}
              />
            </div>
          </div>
        </StyledNavBar>
        <StyledDesktopItems>
          <div
            data-testid="burger-desktop"
            className="burger-desktop"
            onClick={() => setSideNavOpen(true)}
          >
            <BurgerNav sideNavOpen={sideNavOpen} />
            <p>Menu</p>
          </div>
          <div className="divider" />
          <div className="category-links-desktop">
            {navData.categories.map(({ name }) => (
              <NavLink
                dataTestId={`desktop-links-${name}`}
                href={`${productsUrl}${categoryForUrl(name.toLowerCase())}`}
                title={name}
                LinkComponent={LinkComponent}
                key={name}
              />
            ))}
          </div>
        </StyledDesktopItems>
      </StyledNavBarWrapper>
      <SideNav
        isOpen={sideNavOpen}
        onClose={handleCloseSideNav}
        navData={navData}
        subMenuOpen={subMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        setSideNavOpen={setSideNavOpen}
        accountHomeUrl={accountHomeUrl}
        productsUrl={productsUrl}
        LinkComponent={LinkComponent}
      />
    </>
  );
};
