import { rayloLight } from '../rayloLight';
import { affordableMobilesPalette } from './colours';

export const navBar = {
  ...rayloLight.navBar,
  backgroundColor: affordableMobilesPalette.brand.purple,
  bottomBorder: 'none',
  rayloPayLogoColor: affordableMobilesPalette.white,
  linkColor: affordableMobilesPalette.white,
  hoverLinkColor: affordableMobilesPalette.white,
  disabledLinkColor: affordableMobilesPalette.charcoal[300],
  menuBarsColor: affordableMobilesPalette.white,
};
